@charset "UTF-8";
/* ======================================================

    下層ページメイン画像

====================================================== */
.osk-main {
  position: relative;
  height: 740px;
  background-color: #ff0000;
  background: url(../images/main.jpg) no-repeat 50% 0/cover;
}

.osk-main__inner {
  position: relative;
  max-width: 1200px;
  height: 740px;
  margin: 0 auto;
  z-index: 10;
}

.osk-main__text {
  position: absolute;
  width: calc(250 / 1200 * 100%);
  top: calc(32 / 1200 * 100%);
  left: calc(36 / 740 * 100%);
  z-index: 11;
}

.osk-main__elm1, .osk-main__elm2 {
  position: absolute;
  width: calc(924 / 1200 * 100%);
}

.osk-main__elm1 {
  left: calc(-502 / 1200 * 100%);
  top: calc(256 / 740 * 100%);
}

.osk-main__elm2 {
  left: calc(350 / 1200 * 100%);
  top: calc(353 / 740 * 100%);
}

.osk-main__wave {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  min-width: 1500px;
  font-size: 0;
}

@media screen and (max-width: 1199px) {
  .osk-main {
    padding-top: calc(740 / 1200 * 100%);
    height: auto;
  }
  .osk-main__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .osk-main {
    padding-top: 0;
    height: 360px;
    margin-bottom: 20px;
  }
  .osk-main__text {
    width: 125px;
    top: 15px;
    left: 15px;
  }
  .osk-main__elm1, .osk-main__elm2 {
    width: 462px;
  }
  .osk-main__elm1 {
    left: -275px;
    bottom: 0px;
  }
  .osk-main__elm2 {
    left: auto;
    right: -100px;
    bottom: -20%;
  }
  .osk-main__wave {
    left: 0;
    transform: none;
    min-width: auto;
  }
}

/* ======================================================

    

====================================================== */
.osk-top-text {
  padding: calc( 75 / 1200 * 100%) 0;
}

.osk-cards.row {
  padding-top: calc( 105 / 1200 * 100%);
  margin-left: -12px;
  margin-right: -12px;
}

.osk-cards .col-sm-4 {
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
  color: inherit;
}

.osk-cards a {
  transition: opacity 300ms;
}

.osk-cards a:hover {
  opacity: 0.7;
}

.osk-util-shadow:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 12px;
  right: 12px;
  bottom: 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

.osk-card {
  font-size: 1.2rem;
  text-align: center;
}

.osk-card img {
  width: 100%;
}

.osk-card__inner {
  padding: 0 10px 50px 10px;
}

.osk-card__title.icon-humen:before,
.osk-card__title.icon-gear:before,
.osk-card__title.icon-tube:before {
  display: block;
  margin-bottom: 12px;
  font-size: 4.0rem;
}

.osk-card__title {
  font-size: 2.3rem;
  margin-top: 22px;
  margin-bottom: 6px;
}

.osk-card__title-sub {
  font-family: 'Quicksand', sans-serif;
  color: #0C2D80;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  @supports (object-fit: cover) {
    .osk-bg-factory img {
      width: 100%;
      height: 204px;
      object-fit: cover;
      object-position: 50% 0;
    }
  }
}
